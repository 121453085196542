import "./QuantityDropdown-style";
import { InventoryStore, InventoryKind, ProductInventory, InventoryOverview } from "../inventory/InventoryStore";
import { productStore } from "../Products";



class QuantityDropdownElement extends HTMLElement {
    private isIE = () => window.navigator.userAgent.match(/(MSIE|Trident)/);
    private options = [1, 2, 3, 4, 5, 6, 7, 8, 12, 16, 20, 24, 28, 40];
    private maxValue = () => parseInt(this.getAttribute("max-value"));
    private isCritical = () => this.hasAttribute("critical");
    private itemId = () => parseInt(this.getAttribute("item-id"));
    private basePrice = () => parseInt(this.getAttribute("base-price"));
    private basePriceGross = () => parseInt(this.getAttribute("base-price-gross") ?? "0");
    private initialPriceGross = () => parseInt(this.getAttribute("initial-price-gross") ?? "0");
    private initialPriceNet = () => parseInt(this.getAttribute("initial-price-net") ?? "0");
    private mimumumPrice = () => parseInt(this.getAttribute("minimum-price"));
    private inventoryKind = () : InventoryKind => this.getAttribute("inventory-kind") as InventoryKind; 
    private availableAtBasePrice = ()  => parseInt(this.getAttribute("available-at-base-price"));
    private priceAfterLocalStock = () => parseInt(this.getAttribute("price-after-local-stock"));
    private stockLocalDelivery = () => this.hasAttribute("stock-local-delivery") ? new Date(this.getAttribute("stock-local-delivery")) : undefined;
    private stockInPurchaseDelivery = () => this.hasAttribute("stock-in-purchase-delivery") ? new Date(this.getAttribute("stock-in-purchase-delivery")) : undefined;
    private stockInPurchaseCount = () => parseInt(this.getAttribute("stock-in-purchase-count"));
    private stockRemoteDelivery = () => new Date(this.getAttribute("stock-remote-delivery")); 

    private _alreadyInBasket = 0;
    private _discountEligible = 0;
    private selectElement: HTMLSelectElement;
    private inFocus = false;

    // min and max included 
    private random = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);


    private formatPrice = (price: number) => {
        let formatted = (Math.round(price) / 100).toLocaleString(navigator.language,  { minimumFractionDigits: 2 });
        if (!document.documentElement.hasAttribute("camouflaged-prices"))
            return formatted;

        const major = formatted
                        .substring(0, formatted.length - 3)
                        .replace(",", "")
                        .replace(".", "");
        
        return `${this.random(1,9)}.${major}:${this.random(0,9)}${this.random(0,9)}`;
    } 

    private isDiscountApplicable = () => {
        if (document.documentElement.hasAttribute("oe-shop"))
            return false;

        if (!document.documentElement.hasAttribute("bulk-discount"))
            return false;

        if (isNaN(this.mimumumPrice()))
            return false;

        // has minimum price already been reached?
        if (this.basePrice() === this.mimumumPrice())
            return false;

        return this.inventoryKind() === "local";
    } 

    
    private _discountSteps = [
        { from: 0, to: 7, percent: 0 },
        { from: 8, to: 11, percent: 1 },
        { from: 12, to: 15, percent: 2 },
        { from: 16, to: 19, percent: 3 },
        { from: 20, to: 31, percent: 4 },
        { from: 32, to: 47, percent: 5 },
        { from: 48,  to: 99, percent:  6 },
        { from: 100, to: 100, percent: 7 },
        
    ];

    private _increasedPrice: number[] = [];
   

    private calculateItemPriceBasedOnQuantity(quantity: number): number {

        if (isNaN(quantity))
            quantity = 1;

        if (this.inventoryKind() != "local")
            return this.basePrice();

        // if none are on local stock assume that the payPrice is correct
        if (this.availableAtBasePrice() <= 0) {
            return this.basePrice();
        }

        const availableAtBasePrice = Math.max(0, this.availableAtBasePrice());


        // Calculate how many items are bought at the PayPrice
        const atPayPriceCount: number = Math.min(quantity, availableAtBasePrice);
      
        // Calculate how many items are bought at the PriceAfterLocalStock
        const atIncreasedPriceCount: number = Math.max(0, quantity - availableAtBasePrice);


        if (atIncreasedPriceCount > 0) {
            this.toggleAttribute("increased-price", true)
            this._increasedPrice.push(quantity);
        }
        
        // Calculate the total cost
        const totalCost: number = (atPayPriceCount * this.basePrice()) +
                                  (atIncreasedPriceCount * (this.priceAfterLocalStock() ?? this.basePrice()));
      
        // Calculate the price per item
        const pricePerItem: number = totalCost / quantity;
      
        return pricePerItem;
      }

    // NOTE: we only listen to render, b/c scripts around us changes these attributes a LOT!
    static get observedAttributes() { return ["render"]; }
    
    private get value() {
        return parseInt(this.getAttribute("value"));
    }

    private set value(value: number) {
        this.setAttribute("value", value.toString());
    }
    
    connectedCallback() {
        if (this.hasAttribute("disabled"))
            return;

        this.selectElement = document.createElement("select");
        this.appendChild(this.selectElement);

        this.refresh();
        
        this.addEventListener("change", this.dispatchPriceChangeEvent);
        this.selectElement.addEventListener("focus", this.focusHandler)
        this.selectElement.addEventListener("blur", this.blurHandler)

        InventoryStore.instance.addEventListener("updated", this.refresh);

        setTimeout(() => {
            this.verify();
        }, 1000);


        this.selectElement.addEventListener("input", this.onInput);
    }

    disconnectedCallback() {
        if (this.selectElement) {
            this.selectElement.removeEventListener("focus", this.focusHandler)
            this.selectElement.removeEventListener("blur", this.blurHandler)    
        }
        this.removeEventListener("change", this.dispatchPriceChangeEvent);

        
        this.selectElement.removeEventListener("input", this.onInput);

        
        InventoryStore.instance.removeEventListener("updated", this.refresh);
    }

    private onInput = () => {
        this.dispatchQuantityChangeEvent();
        this.dispatchDeliveryDateChangeEvent();
    }

    attributeChangedCallback(name, oldValue, newValue) {
        this.refresh();
    }

    private focusHandler = () => {
        this.inFocus = true;

        // const inventory = await InventoryStore.instance.getInventory(this.itemId());
        // console.log(inventory);
        // this._inventory = JSON.parse(atob(this.getAttribute("inventory")));
        // console.log(this._inventory);

        this.refresh();
    }

    private blurHandler = () => {
        this.inFocus = false;
        this.refresh();
    }

    private discountPercentForStep(step: number) {
        if (!this.isDiscountApplicable())
            return 0;

        var actualCount = this._discountEligible + step;
        const discountStep = this._discountSteps.filter(m => m.from <= actualCount && m.to >= actualCount)[0];
        return discountStep ? discountStep.percent : 0;
    }

    private verify() {

        if (!this.offsetParent) // only validate visible elements
            return;

        if (!this.inventoryKind()) 
            throw new Error(`Element does not have a inventory-kind: ${this.outerHTML}`);
            
        if (!this.isDiscountApplicable())
            return;

        if (isNaN(this.mimumumPrice()))
            throw new Error(`Item does not have a minimum price: ${this.outerHTML}`);
        
        if (isNaN(this.basePrice()))
            throw new Error(`Item does not have a base price: ${this.outerHTML}`);

        if (this.inventoryKind() == "local") {
            if (isNaN(this.availableAtBasePrice()))
                throw new Error(`Item does not have a 'available-at-base-price': ${this.outerHTML}`);

            if (isNaN(this.priceAfterLocalStock()))
                throw new Error(`Item does not have a 'price-after-local-stock': ${this.outerHTML}`);
        }
    }

    private priceMinorForStep(step: number) {
        const price = this.calculateItemPriceBasedOnQuantity(step);
        const discountPrice = price - ((price / 100) * this.discountPercentForStep(step));

        if (discountPrice < this.mimumumPrice())
            return this.mimumumPrice();

        return discountPrice;
    }

    private basePriceForStep(step: number) {
        return this.formatPrice(this.calculateItemPriceBasedOnQuantity(step));
    }

    private debugBasePriceForStep(step: number) {
        return this.formatPrice(this.basePrice());
    }

    
    private debugIncreasedPriceForStep(step: number) {
        return this.formatPrice(this.priceAfterLocalStock());
    }

    private priceForStep(step: number) {
        return this.formatPrice(this.priceMinorForStep(step));
    }

    private dispatchPriceChangeEvent = () => {
        const select = this.querySelector("select") as HTMLSelectElement;
        this.value = parseInt(select.value);

        const priceMinor = this.priceMinorForStep(this.value);

        const totalMinor = priceMinor * this.value;
        
        this.dispatchEvent(new CustomEvent<Price>("price-change", { 
            bubbles: true, 
            detail: {
                minor: priceMinor,
                formatted: this.formatPrice(priceMinor),
                quantity: this.value,
                totalMinor: totalMinor,
                totalFormatted: this.formatPrice(totalMinor),
                completeGrossFormatted: this.formatPrice((this.basePriceGross() * this.value) + (this.initialPriceGross() * this.value)),
                completeNetFormatted: this.formatPrice(totalMinor + (this.initialPriceNet() * this.value)),
                deliveryDate: this.getDeliveryDate(this.value)
            } 
        }));
    }

    private dispatchQuantityChangeEvent = () => {
        this.dispatchEvent(new CustomEvent<string>("quantity-change", { bubbles: true, detail: this.selectElement.value }));
    }

    
    private dispatchDeliveryDateChangeEvent = () => {
        const deliveryDate = this.getDeliveryDate(parseInt(this.selectElement.value));

        if (deliveryDate)
            this.dispatchEvent(new CustomEvent<Date>("delivery-date-change", { bubbles: true, detail: deliveryDate }));
    }

    private getOptions() : number[] {
        const quantities = this.options
            .slice(0)
            .filter(qty => qty <= (this.maxValue() - this._alreadyInBasket))

        if (!this.isCritical())
            return quantities;

        if (this.getAttribute("kind") === "tyre")
            if (this.maxValue() % 2 === 0)
                return quantities.filter(qty => qty % 2 === 0);
            else
                return quantities;
        else
            return quantities.filter(qty => qty % 4 === 0 || this.maxValue() % 4 >= qty % 4);
    }

    private findBestValue() : number {
        const options = this.getOptions();

        if (options.length === 0)
            return 0;

        return options.reduce((prev, curr) => (Math.abs(curr - this.value) < Math.abs(prev - this.value) ? curr : prev));
    }   

    private refresh = async () => {
        this._alreadyInBasket = await InventoryStore.instance.getBasketTotalCount(this.itemId());
        const oldDiscountEligible = this._discountEligible;
        this._discountEligible = await InventoryStore.instance.getDiscountEligible();

        const bestValue = this.findBestValue()
        if (this.value !== bestValue)
            this.value = bestValue;
        
        if (this.isDiscountApplicable() && (this.isIE() || this.inFocus))
            this.selectElement.innerHTML = this.discountView(this.getOptions(), this.value);
        else
            this.selectElement.innerHTML = this.view(this.getOptions(), this.value);

        this.dispatchPriceChangeEvent();
    }

    private formatDate = (date: Date) => { 
        if (!date)
            return "ukendt";

        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
    }

    private getDeliveryDate = (quantity: number) => {
        const total = quantity + this._alreadyInBasket;

        if (total <= this.availableAtBasePrice() && this.stockLocalDelivery() !== undefined)
            return this.stockLocalDelivery();

        // if (total <= this.availableAtBasePrice() + this.stockInPurchaseCount() && this.stockInPurchaseDelivery() !== undefined)
        //     return this.stockInPurchaseDelivery();
        
        let wantedQuantity =quantity;
        wantedQuantity -= this._alreadyInBasket;
        wantedQuantity -= this.availableAtBasePrice();
        // wantedQuantity -= this.stockInPurchaseCount();

        const vendor = productStore.getVendorByQuantity(this.itemId(), wantedQuantity);
        if (!vendor)
            return;

        return vendor.shippingDate;
    }

    view = (quantities: number[], selected: number) => `

            ${quantities.map(quantity => `<option ${quantity === selected ? "selected": ""}>${quantity}</option>`).join("")}
    `;

    
    private sameDate = (a: Date, b: Date) => {
        if (!a && !b)
            return true;

        if (!a || !b)
            return false;

        // ignore time
        const aDate = new Date(a.getFullYear(), a.getMonth(), a.getDate());
        const bDate = new Date(b.getFullYear(), b.getMonth(), b.getDate());
        return aDate.getTime() === bDate.getTime();
    };

    private optionView = (quantity: number, selected: number) => `
        <option 
            ${quantity === selected ? "selected": ""}  
            ${this._increasedPrice.indexOf(this._alreadyInBasket + quantity) == -1 ? "": "increased-price"} 
        >${quantity} á ${this.priceForStep(quantity + this._alreadyInBasket)}</option>`;


    private _previousDeliveryDate: Date;
    discountView = (quantities: number[], selected: number) => {
        let result = "";

        for (const quantity of quantities) {
            const deliveryDate = this.getDeliveryDate(quantity);


            if (!this.sameDate(deliveryDate, this._previousDeliveryDate)) {
                if (this._previousDeliveryDate)
                    result += `</optgroup>`;

                result += `<optgroup label="Levering ${this.formatDate(deliveryDate)}">`;
                this._previousDeliveryDate = deliveryDate;
            }

            result += this.optionView(quantity, selected);
        }


        this._previousDeliveryDate = undefined;

        return result;
    }
        
        
        
    //     `
    //         <optgroup label="Levering 10/10/2020">
    //         ${quantities.map(quantity => `<option 
    //             ${quantity === selected ? "selected": ""}  
    //             ${this._increasedPrice.indexOf(this._alreadyInBasket + quantity) == -1 ? "": "increased-price"} 
    //         >${quantity} á ${this.priceForStep(quantity + this._alreadyInBasket)} ${this.formatDate(this.getDeliveryDate(quantity))}</option>`).join("")}
    //         </optgroup>
    // `;
}

interface Price {
    minor: number;
    formatted: string;
    quantity: number;
    totalMinor: number;
    totalFormatted: string;
    completeNetFormatted: string;
    completeGrossFormatted: string;
    deliveryDate: Date;
}

customElements.define("quantity-dropdown", QuantityDropdownElement);