declare var productsDataString: any;

export class ProductStore  {
    private static _instance = new ProductStore();
    private _products: Product[];
    public static get instance() { return this._instance; }

    private constructor() {

    }

    private initializeProducts() { 
        if (!productsDataString)
            return;

        const parsed = JSON.parse(productsDataString) as ProductData;
        this._products = [];

        // flatten tyres
        for (const season of parsed.items) {

            if (!season.TyreSizes)
                continue;
            
            for (const size of season.TyreSizes) {

                if (!size.Tyres)
                    continue;

                for(const tyre of size.Tyres) {

                    const internalVendorDeliveries = JSON.parse(tyre.vendorDeliveries) as any[];

                    const vendorDeliveries = internalVendorDeliveries.map(vd => ({    
                        stockAvailable: vd.stockAvailable,
                        shippingDate: new Date(vd.shippingDate)
                    }));


                    // Sort vendor deliveries with the soonest shipping date first
                    vendorDeliveries.sort((a, b) => a.shippingDate.getTime() - b.shippingDate.getTime());

                    this._products.push({
                        itemId: tyre.ia,
                        vendorDeliveries: vendorDeliveries
                    });
                }
            }
        }

    }

    public getVendorByQuantity(itemId: number, quantity: number) : VendorDelivery | undefined {
        if (!this._products)
            this.initializeProducts();

        if (!this._products)
            return undefined;

        const product = this._products.find(p => p.itemId === itemId);

        if (!product)
            return undefined;

        let wantedQuantity = quantity;
        
        for (const vendorDelivery of product.vendorDeliveries) {
            if (vendorDelivery.stockAvailable >= wantedQuantity) {
                return vendorDelivery;
            } else {
                wantedQuantity -= vendorDelivery.stockAvailable;
            }
        }

        return undefined;
    }

    // public init() {
    //     if (!this._products)
    //         this.initializeProducts();
    // }        
}

interface Tyre {
    ia: number;
    vendorDeliveries: string;
}

interface TyreSize {
    Tyres: Tyre[];
}

interface TyreSeason {
    TyreSizes: TyreSize[];
}

interface ProductData {
    items: TyreSeason[];
}

interface VendorDelivery {
    stockAvailable: number;
    shippingDate: Date;
}

export interface Product {
    itemId: number;
    vendorDeliveries: VendorDelivery[];
}